import React, { ReactNode, useEffect } from 'react';
import { siteStore, SiteStoreContext, useSiteStore } from './store/site';
import { configure, toJS } from 'mobx';
import classNames from 'classnames';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowUp,
  faBackspace,
  faBars,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faExclamationCircle,
  faHeart,
  faInfoCircle,
  faPlus,
  faSearch,
  faSlidersH,
  faStar,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartO } from '@fortawesome/free-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import { Alert } from './components/organisms/Alert';
import { CommonModals } from './components/organisms/CommonModals';
import { AppNavbar } from './components/organisms/AppNavbar';
import { ConfirmModal } from './components/molecules/ConfirmModal';
import { Footer } from './components/organisms/Footer';

import styles from './MainLayout.module.scss';
import { AnnounceBox } from './components/AnnounceBox/AnnounceBox';
import { AnnounceManager } from './components/AnnounceBox/AnnounceManager';

library.add(
  faBars,
  faSearch,
  faInfoCircle,
  faUpload,
  faEdit,
  faStar,
  faExclamationCircle,
  faChevronLeft,
  faChevronRight,
  faBackspace,
  faPlus,
  faHeart,
  faHeartO,
  faArrowUp,
  faSlidersH,
  faCheck
);

configure({
  enforceActions: 'never',
});

type Props = {
  children?: ReactNode | ReactNode[];
  className?: string;
  mainClassName?: string;
  hasNavbar?: boolean;
  hasNavigation?: boolean;
  hasFooter?: boolean;
};

export const MainLayout: React.FC<React.PropsWithChildren<Props>> = observer(function MainLayout({
  className,
  children,
  mainClassName,
  hasNavbar = true,
  hasNavigation = true,
  hasFooter = true,
}) {
  const store = useSiteStore();

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    store.loadAlerts();
  }, [store]);

  return (
    <React.StrictMode>
      <SiteStoreContext.Provider value={siteStore}>
        {store.error && (
          <Alert title={store.errorTitle || 'Error'} onDismiss={() => store.setError(null)}>
            {store.error}
          </Alert>
        )}
        <ConfirmModal />
        <div className={classNames(styles.appContainer, className)}>
          <CommonModals />
          <div className="primary-page-content">
            {hasNavbar && <AppNavbar hasNavigation={hasNavigation} />}
            <main className={classNames(styles.mainContent, mainClassName)}>
              <AnnounceBox />
              <AnnounceManager />
              {children}
            </main>
          </div>
          {hasFooter && <Footer />}
        </div>
      </SiteStoreContext.Provider>
    </React.StrictMode>
  );
});

const win: any = globalThis;
win.toJS = toJS;
